.keyboardButton {
    width: 56px;
    height: 56px;
    border: 3px solid #667080;
    border-radius: 16px;
    font-family: Roboto, serif;
    background: #1c1c1c;
    color: #FFFFFF;
    font-weight: 500;
    font-size: 32px;
    margin: 8px 6px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.keyboardButton:active {
    background: #FFCD29;
    color: #1c1c1c;
    border-color: #FFCD29;
}

.keyboardButtonDelete {
    border-color: #f71e55;
    align-items: center;
    justify-content: center;
    display: inline-flex;
}

.keyboardButtonDelete > * {
    position: relative;
    top: -2px
}

.keyboardLetter > .keyboard > .keyboardRow:nth-child(2) {
    margin-left: 27px;
}

.keyboardNumpad, .keyboardPhone > .keyboard {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mainKeyboardContainer {
    width: 110%;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: #111111;
    height: 296px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.keyboardContainer {
    width: 112%;
    margin-left: -57px;
    margin-bottom: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.keyboardPhone > .keyboard > .keyboardRow > .keyboardButton {
    width: 104px;
    height: 104px;
    font-size: 56px;
    font-weight: 500;
    margin: 12px 12px;
    border: 4px solid #667080;
    border-radius: 24px;
}

.keyboardPhone > .keyboard > .keyboardRow > .keyboardButtonDelete {
    border-color: #f71e55;
}

.keyboardPhone > .keyboard > .keyboardRow > .keyboardButtonDelete img {
    width: 42px;
    height: 39px;
}
